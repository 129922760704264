import Head from 'next/head'
import React from 'react'
import type { SeoType } from '../pages/[[...page]]'

export interface SeoProps {
	seo: SeoType | undefined
}

export const Seo: React.FunctionComponent<SeoProps> = ({ seo }) => {
	const ogImage = seo?.ogImage || {
		url: '/og-image.jpg',
		width: 1200,
		height: 630,
	}
	return (
		<Head>
			<title>{seo?.title ? `${seo?.title} | ` : ''}Rokytnice</title>
			<meta name="description" content={seo?.description} />
			<meta property="og:title" content={seo?.ogTitle || seo?.title} />
			<meta property="og:image" content={ogImage.url} />
			{ogImage.width && <meta property="og:image:width" content={`${ogImage.width}`} />}
			{ogImage.height && <meta property="og:image:height" content={`${ogImage.height}`} />}
			<meta property="og:description" content={seo?.ogDescription || seo?.description} />
		</Head>
	)
}
