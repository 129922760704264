import React from 'react'
import { Link } from '../components/Link'
import type { NavType } from '../pages/[[...page]]'
import { LanguageSwitcher } from './LanguageSwitcher'

export interface NavProps {
	menu: NavType | undefined
	locale: string
}

export function Nav(props: NavProps) {
	const [scrollState, setScrollState] = React.useState('top')

	React.useEffect(() => {
		document.addEventListener('scroll', () => {
			if (document.scrollingElement && document.scrollingElement.scrollTop >= 50) {
				if (scrollState !== 'amir') {
					setScrollState('amir')
				}
			} else {
				if (scrollState !== 'top') {
					setScrollState('top')
				}
			}
		})
	}, [scrollState])

	const [menuOpen, setMenuOpen] = React.useState<boolean>(false)

	const toggle: React.MouseEventHandler<HTMLButtonElement> = React.useCallback((e) => {
		e.preventDefault()
		setMenuOpen((old) => (old ? false : true))
	}, [])

	return (
		<nav
			className={`nav ${scrollState === 'amir' ? 'scrolled' : ''} ${menuOpen ? 'open' : ''}`}>
			<ul className="nav-menu">
				<li>
					<button className="nav-menu-toggler" onClick={toggle}>
						MENU
					</button>
				</li>
				{props.menu?.items?.map((item, index) => (
					<li key={index}>
						<Link nonExternal link={item.localesByLocale?.link} />
					</li>
				))}
			</ul>
			<LanguageSwitcher locale={props.locale} />
		</nav>
	)
}
