import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import { Link } from '../components/Link'
import { Picture } from '../components/Picture'
import type { ContentBlockReference } from '../queries/ContentQuery'

export const header = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	console.log(children)
	return (
		<header id={`section-${reference.block?.order}`} className="header section">
			<div className="header-inner">
				{reference.headerTiles?.items.map((item, index) => (
					<div className="header-inner-single" key={index}>
						<Link link={item.link} nonExternal />
						<Picture
							src={item.bgImage?.url ?? ''}
							height={item.bgImage?.height}
							width={item.bgImage?.width}
							alt={''}
							cover
						/>
						<div className="header-inner-single-content">
							<div className="header-inner-single-content-logo">
								<Picture
									src={item.logo?.url ?? ''}
									height={item.logo?.height}
									width={item.logo?.width}
									alt={''}
									//contain
								/>
							</div>
							<h2>{item.headline}</h2>
						</div>
					</div>
				))}
			</div>
		</header>
	)
}
