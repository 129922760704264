import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import { Link } from '../components/Link'
import { Picture } from '../components/Picture'
import type { ContentBlockReference } from '../queries/ContentQuery'

export const partners = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	console.log(children)
	console.log(reference)
	return (
		<section className={`partners section ${reference.link ? '' : 'view-center'}`}>
			<div className="container">
				<div className="partners-inner">
					{reference.partners?.items.map((item, index) => (
						<div
							// data-aos="fade"
							// data-aos-delay={50 * index}
							className="partners-inner-single logo"
							key={index}>
							<Link nonExternal link={item.link} />
							<Picture
								src={item.logo?.url ?? ''}
								height={item.logo?.height}
								width={item.logo?.width}
								alt={''}
								//contain
							/>
						</div>
					))}
					<Link
						nonExternal
						link={reference.link} //animation="fade" animationDelay={100}
					/>
				</div>
			</div>
		</section>
	)
}
