/* eslint-disable react-hooks/rules-of-hooks */
import type { ReferenceRendererProps } from '@contember/react-client'
import { LightboxSource, useLightbox } from '@mangoweb/react-fslightbox'
import type { FunctionComponent } from 'react'
import { Picture } from '../components/Picture'
import type { ContentBlockReference } from '../queries/ContentQuery'
import { filterNonEmpty } from '../utils/filterNonEmpty'

export type GalleryProps = ReferenceRendererProps<ContentBlockReference>

export const gallery: FunctionComponent<GalleryProps> = ({ reference }) => {
	const elements =
		reference.gallery?.items
			.map((image): LightboxSource => {
				const source = image.image?.url
				if (source) {
					return {
						source,
						type: 'image',
					}
				}
			})
			.filter(filterNonEmpty) ?? []

	const lightbox = useLightbox(elements, {})
	return (
		<section className="gallery section">
			<div className="container">
				<h2 data-aos="fade">{reference.primaryText}</h2>
				<div className="gallery-inner">
					{reference.gallery?.items.map((item, index) => (
						// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
						<div
							className="gallery-inner-single"
							key={item.id}
							onClick={() => lightbox?.open(index)}
							data-aos="fade"
							data-aos-delay={50 * index}>
							<Picture
								src={item.image?.url ?? ''}
								height={item.image?.height}
								width={item.image?.width}
								alt={''}
								cover
							/>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}
