/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

type LanguageSwitcherProps = {
	locale: string
}

export function LanguageSwitcher(props: LanguageSwitcherProps) {
	const [LanguageSwitcherToggle, setLanguageSwitcherToggle] = React.useState<boolean>(false)

	const toggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> =
		React.useCallback((e) => {
			e.preventDefault()
			setLanguageSwitcherToggle((old) => !old)
		}, [])

	return (
		<div className="lngSwitcher">
			<ul className={LanguageSwitcherToggle ? 'open' : ''}>
				<li className={props.locale === 'cs' ? 'active' : ''}>
					<a href="/">
						<span className="flag-icon flag-icon-cz"></span>
					</a>
				</li>
				<li className={props.locale === 'pl' ? 'active' : ''}>
					<a href="/pl">
						<span className="flag-icon flag-icon-pl"></span>
					</a>
				</li>
				<li className={props.locale === 'en' ? 'active' : ''}>
					<a href="/en">
						<span className="flag-icon flag-icon-gb"></span>
					</a>
				</li>
			</ul>
			<button onClick={toggle}>
				<span></span>
				<span></span>
			</button>
		</div>
	)
}
