/* eslint-disable jsx-a11y/anchor-is-valid */
import NextLink from 'next/link'
import React from 'react'
import type { LinkResultType } from '../queries/ContentQuery'

type LinkProps = {
	link: LinkResultType
	class?: string
	nonExternal?: boolean
	animation?: string
	animationDelay?: number
	children?: React.ReactNode
	titleIsHidden?: boolean
}

export function Link(props: LinkProps) {
	const titleIsHidden = props.titleIsHidden ?? false
	// if (!props.link) {
	//   return "tady je hlava";
	// }
	if (props.link?.externalLink || props.link?.internalLink) {
		const external = Boolean(props.link.externalLink)
		const url = (
			props.link.internalLink?.url ? props.link.internalLink.url : props.link.externalLink
		) as string
		return (
			<NextLink href={url}>
				<a
					data-aos={props.animation ? props.animation : ''}
					data-aos-delay={props.animationDelay ? props.animationDelay : ''}
					className={props.class ? props.class : 'btn'}
					target={external && !props.nonExternal ? '_blank' : ''}>
					{!titleIsHidden && props.link.title}
					{props.children}
				</a>
			</NextLink>
		)
	} else {
		return null
	}
}
