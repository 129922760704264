import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import { Picture } from '../components/Picture'
import type { ContentBlockReference } from '../queries/ContentQuery'

export const image = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	console.log(children)
	console.log(reference)
	return (
		<section className="image section" data-aos="fade">
			<div className="container">
				<Picture
					src={reference.image?.url ?? ''}
					height={reference.image?.height}
					width={reference.image?.width}
					alt={''}
					//contain
				/>
			</div>
		</section>
	)
}
