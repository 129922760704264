import { RichTextRenderer } from '@contember/react-client'
import type { ContentResult } from '../queries/ContentQuery'
import * as referenceRenderers from '../referenceRenderers'

export interface ContentProps {
	blocks: ContentResult['blocks']
}

export const Content = (props: ContentProps) => {
	return (
		<RichTextRenderer
			blocks={props.blocks}
			sourceField="json"
			referenceRenderers={referenceRenderers}
			renderElement={(props) => {
				console.log(props)
				if (props.element.type === 'heading' || props.element.type === 'paragraph') {
					return (
						<div
							className={`container ${
								props.element.type === 'heading' ? 'heading' : ''
							}`}
							data-aos="fade">
							{props.fallback}
						</div>
					)
				} else if (props.element.type !== 'reference' && props.element.type !== 'anchor') {
					return <div className="content">{props.fallback}</div>
				}
				return props.fallback
			}}
		/>
	)
}
