import Image from 'next/image'
import React from 'react'

export interface PictureProps {
	alt: string
	cover?: boolean
	contain?: boolean
	width?: number
	height?: number
	src: string
	sizes?: string // @TODO: doplnit všude sizes a tady je udělat povinné
}

const storageUrlFragment = 'cms-api.mgw.cz/'

export const Picture: React.FunctionComponent<PictureProps> = (props) => {
	const { alt, cover, contain, width = 1, height = 1, src, sizes } = props
	const srcProps = React.useMemo(() => {
		const fragmentPosition = src.indexOf(storageUrlFragment)
		if (fragmentPosition >= 0) {
			return { src: src.substring(fragmentPosition + 15) }
		}
		return {
			src,
			unoptimized: true,
		}
	}, [src])

	const objectFit = contain ? 'contain' : cover ? 'cover' : undefined

	if (props.src !== '') {
		return (
			<Image
				{...srcProps}
				sizes={sizes}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore: responsive může být jen v kombinaci s width a height
				layout={objectFit ? 'fill' : 'responsive'}
				alt={alt}
				objectFit={objectFit}
				width={objectFit ? undefined : width}
				height={objectFit ? undefined : height}
				className="picture"
			/>
		)
	} else {
		return null
	}
}
