import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import { Picture } from '../components/Picture'
import type { ContentBlockReference } from '../queries/ContentQuery'

export const textAndImage = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	console.log(children)
	console.log(reference)
	return (
		<section className={`textAndImage section ${reference.textAndImageView}`}>
			<div className="textAndImage-inner">
				<div
					className="textAndImage-inner-image"
					data-aos="fade"
					data-aos-delay={reference.textAndImageView == 'left' ? 100 : 0}>
					<Picture
						src={reference.image?.url ?? ''}
						height={reference.image?.height}
						width={reference.image?.width}
						alt={''}
						cover
					/>
				</div>
				<div
					className="textAndImage-inner-content"
					data-aos="fade"
					data-aos-delay={reference.textAndImageView == 'right' ? 100 : 0}>
					{reference.primaryText && (
						<h2>
							{reference.secondaryImage && (
								<img src={reference.secondaryImage?.url} alt="" />
							)}
							<span>{reference.primaryText}</span>
						</h2>
					)}
					<article>{children}</article>
				</div>
			</div>
		</section>
	)
}
