import type { ReferenceRendererProps } from '@contember/react-client'
import { Link } from '../components/Link'
import { Picture } from '../components/Picture'
import type { ContentBlockReference } from '../queries/ContentQuery'

export const virtualTour = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	console.log(children)
	return (
		<div className="virtualTour">
			<div className="container">
				<Link link={reference.link} class="virtualTour-link" titleIsHidden={true}>
					<p className="virtualTour-linkTitle">{reference.link?.title}</p>
					<div className="virtualTour-image">
						<Picture
							src={reference.image?.url ?? ''}
							height={reference.image?.height}
							width={reference.image?.width}
							alt={''}
						/>
					</div>
				</Link>
			</div>
		</div>
	)
}
